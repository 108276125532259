var debug = require('debug')('orka:ecouteur')

let wsUsager = null
let wsChaine = null

var urlSocketChaine = 'wss://agora.connexence.com/ws/orka-' + process.env.VUE_APP_CONTEXTE
var urlSocketUsager = 'wss://agora.connexence.com/notification/ws/orka-' + process.env.VUE_APP_CONTEXTE

const cbUsager = {}

const mapWsChaine = {}

const cbChaine = {}

const Ecouteur = {
    ecouterUsager(nomUsager, ecouteur) {
        // console.log('ecouter usager', nomUsager)
        initWsUsager(nomUsager)
        const keys = Object.keys(cbUsager)
        let id
        if (keys.length === 0) {
            id = 0
        } else {
            id = keys[keys.length - 1] + 1
        }

        cbUsager[id] = ecouteur

        return id
    },
    retirerEcouteurUsager(id) {
        if (cbUsager[id]) {
            delete cbUsager[id]
        }
        wsUsager.close(4001)
        console.log('Mon écouteur est fermé !')
    },
    ecouterChaine(chaine, ecouteur) {
        debug('ecouterChaine', chaine, ecouteur)
        initWsChaine(chaine)

        let cbs = cbChaine[chaine]
        if (!cbs) {
            cbs = {}
            cbChaine[chaine] = cbs
        }

        const keys = Object.keys(cbs)
        let id
        if (keys.length === 0) {
            id = 0
        } else {
            id = keys[keys.length - 1] + 1
        }

        cbs[id] = ecouteur

        return id
    },
    retirerEcouteurChaine(chaine, id) {
        const cbs = cbChaine[chaine]
        if (cbs && cbs[id]) {
            delete cbs[id]
        }
        wsChaine.close(4001)
        console.log('Mon écouteur est fermé !')
    },
    getCbUsager() {
        return cbUsager
    },

}

function initWsUsager(nomUsager) {
    if (wsUsager && wsUsager.readyState < 2) {
        return
    }

    const url = urlSocketUsager + '/' + encodeURIComponent(nomUsager)
    debug('Creating websocket for user (' + nomUsager + ')notifications : ' + url)
    wsUsager = new WebSocket(url)

    wsUsager.onclose = (action) => {
        if (action.code !== 4001) {
            initWsUsager(nomUsager)
        }
    }

    wsUsager.onmessage = onMessageUsager
}

function onMessageUsager(event) {
    debug(event)
    Object.keys(cbUsager).forEach((id) => {
        cbUsager[id](JSON.parse(event.data))
    })
}

function initWsChaine(chaine) {
    wsChaine = mapWsChaine[chaine]
    if (wsChaine && wsChaine.readyState < 2) {
        return
    }

    const url = urlSocketChaine + '/' + chaine
    debug('Creating websocket for channel notifications, channel : ' + chaine + ' - ' + url)

    wsChaine = new WebSocket(url)
    mapWsChaine[chaine] = wsChaine

    wsChaine.onclose = (action) => {
        console.log(action)
        if (action.code !== 4001) {
            initWsChaine(chaine)
        }
    }

    wsChaine.onmessage = (event) => {
        debug('onmessage', event)
        const cbs = cbChaine[chaine]
        if (cbs) {
            const json = JSON.parse(event.data)
            const derniereEntree = json[json.length - 1]
            if (derniereEntree.infos && derniereEntree.infos.type === 'notification') {
                Object.keys(cbs).forEach((id) => {
                    cbs[id](derniereEntree.infos.donnees)
                })
            }
        }
    }
}

window.ecouteur = Ecouteur
export default Ecouteur
