import axios from 'axios'
var debug = require('debug')('orka:emetteur')

var urlSocket = 'https://agora.connexence.com/notification/orka-' + process.env.VUE_APP_CONTEXTE

const Emetteur = {
    emettreUsager (nomUsager, donnees) {
        debug('emettreUsager', nomUsager, donnees)
        axios({
            url: urlSocket,
            method: 'POST',
            data: {
                nomUsager,
                donnees,
            },
        }).then((res) => {
            debug(res)
        }).catch((err) => {
            console.error(err)
        })
    },

    emettreChaine (nomChaine, donnees) {
        debug('emettreChaine', nomChaine, donnees)
        axios({
            url: urlSocket,
            method: 'post',
            data: {
                nomChaine,
                donnees,
            },
        }).then((res) => {
            debug(res)
        }).catch((err) => {
            console.error(err)
        })
    },
}

// window.emetteur = Emetteur
export default Emetteur
