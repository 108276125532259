<template>
  <div>
    Usager
    <pre>
        <span
          v-for="msg in notifsUsager"
          :key="msg"
        >{{ msg }}<br>
        </span>
    </pre>
    Chaine 1
    <pre>
        <span
          v-for="msg in chaine1"
          :key="msg"
        >{{ msg }}<br>
        </span>
    </pre>
    Chaine 2
    <pre>
        <span
          v-for="msg in chaine2"
          :key="msg"
        >{{ msg }}<br>
        </span>
    </pre>

    <button @click="envoyerNotif()">
      Envoyer notif usager
    </button>
    <button @click="envoyerChaine1()">
      Envoyer chaine 1
    </button>
    <button @click="envoyerChaine2()">
      Envoyer chaine 2
    </button>
  </div>
</template>

<script>
  import ecouteur from '@/services/ecouteur'
  import emetteur from '@/services/emetteur'

  export default {
    data () {
      return {
        notifsUsager: [],
        chaine1: [],
        chaine2: [],
        idEcouteurUsager: null,
        idEcouteurChaine1: null,
        idEcouteurChaine2: null,
        inc: 0,
      }
    },
    mounted () {
      this.idEcouteurUsager = ecouteur.ecouterUsager('test', this.ecouterNotif)
      this.idEcouteurChaine1 = ecouteur.ecouterChaine('test1', this.ecouterChaine1)
      this.idEcouteurChaine2 = ecouteur.ecouterChaine('test2', this.ecouterChaine2)
    },
    beforeDestroy () {
      ecouteur.retirerEcouteurUsager(this.idEcouteurUsager)
      ecouteur.retirerEcouteurChaine('test1', this.idEcouteurChaine1)
      ecouteur.retirerEcouteurChaine('test2', this.idEcouteurChaine2)
    },
    methods: {
      envoyerNotif () {
        emetteur.emettreUsager('test', {
          champ1: 'valeur1',
          champ2: 'valeur2',
          inc: this.inc++,
        })
      },
      envoyerChaine1 () {
        emetteur.emettreChaine('test1', {
          champ1: 'valeur1',
          champ2: 'valeur2',
          inc: this.inc++,
        })
      },
      envoyerChaine2 () {
        emetteur.emettreChaine('test2', {
          champ1: 'valeur1',
          champ2: 'valeur2',
          inc: this.inc++,
        })
      },
      ecouterNotif (donnees) {
        this.notifsUsager.push(donnees)
      },
      ecouterChaine1 (donnees) {
        this.chaine1.push(donnees)
      },
      ecouterChaine2 (donnees) {
        this.chaine2.push(donnees)
      },
    },
  }
</script>
